import {
  EmploymentStatus,
  Provider,
} from '@getpopsure/public-health-insurance-pricing-engine';
import { TFunction, useTranslation } from 'next-i18next';

import {
  ContributionPrices,
  ContributionPricesInfo,
  PricingInfo,
} from '../contributionPrices';

interface PriceDetailsProps {
  pricingInfo: PricingInfo;
  contributionPrices: ContributionPricesInfo;
  name: Provider;
}

const renderContributionDescription = (
  t: TFunction,
  employmentStatus?: EmploymentStatus
): string => {
  if (employmentStatus === 'EMPLOYED') {
    return t('publichealth:section.table.pricedetails.employedDescription');
  }

  if (employmentStatus === 'SELF_EMPLOYED') {
    return t('publichealth:section.table.pricedetails.selfEmployedDescription');
  }

  if (employmentStatus === 'STUDENT') {
    return t('publichealth:section.table.pricedetails.studentDescription');
  }

  return t('publichealth:section.table.pricedetails.description');
};

const PriceDetails = ({
  contributionPrices,
  name,
  pricingInfo,
}: PriceDetailsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="p-p">
        {renderContributionDescription(t, pricingInfo.employmentStatus)}
      </p>
      <ContributionPrices
        contributionPrices={contributionPrices}
        name={name}
        pricingInfo={pricingInfo}
      />
    </>
  );
};

export default PriceDetails;
