import {
  EmploymentStatus,
  MIN_CAPPED_INCOME,
  MIN_CAPPED_SELF_EMPLOYED_INCOME,
} from '@getpopsure/public-health-insurance-pricing-engine';

export type QuoteStatus =
  | 'LOW_INCOME'
  | 'OTHER'
  | 'STUDENT_OVER_THIRTY'
  | 'SELF_EMPLOYED_MIN_INCOME';

interface PricingInfo {
  employmentStatus?: EmploymentStatus;
  age?: number;
  monthlyIncome?: number;
}

export const getQuoteStatus = (
  pricingInfo: PricingInfo
): QuoteStatus | undefined => {
  if (
    pricingInfo.employmentStatus === 'SELF_EMPLOYED' &&
    pricingInfo.monthlyIncome !== undefined &&
    pricingInfo.monthlyIncome < MIN_CAPPED_SELF_EMPLOYED_INCOME
  ) {
    return 'SELF_EMPLOYED_MIN_INCOME';
  }

  if (
    pricingInfo.employmentStatus === 'EMPLOYED' &&
    pricingInfo.monthlyIncome !== undefined &&
    pricingInfo.monthlyIncome < MIN_CAPPED_INCOME
  ) {
    return 'LOW_INCOME';
  }

  if (pricingInfo.employmentStatus === 'OTHER') {
    return 'OTHER';
  }

  if (
    pricingInfo.employmentStatus === 'STUDENT' &&
    pricingInfo.age &&
    pricingInfo.age > 29
  ) {
    return 'STUDENT_OVER_THIRTY';
  }

  return undefined;
};
