import { faq } from '@getpopsure/private-constants';
import {
  EmploymentStatus,
  MIN_CAPPED_INCOME,
  MIN_CAPPED_SELF_EMPLOYED_INCOME,
} from '@getpopsure/public-health-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import {
  BookOpenIcon,
  CurrencyInput,
  Input,
  PenToolIcon,
  UserIcon,
  UsersIcon,
} from '@popsure/dirty-swan';
import classnames from 'classnames';
import { TFunction, useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';

import { getQuoteStatus, QuoteStatus } from './getQuoteStatus';
import styles from './style.module.scss';

interface EmploymentData {
  id: EmploymentStatus;
  label: string;
  iconUrl: { active: string | ReactNode; inactive: string | ReactNode };
}

export interface CalculatorVariables {
  employmentStatus: EmploymentStatus;
  age: number;
  annualIncome: number;
  haveChildren: boolean;
}

interface EmploymentRadioFormProps {
  total: number | null;
  variables: Partial<CalculatorVariables>;
  onChange: (
    key: keyof CalculatorVariables,
    value?: CalculatorVariables[keyof CalculatorVariables]
  ) => void;
}

const employmentData = (t: TFunction): Array<EmploymentData> => [
  {
    id: 'EMPLOYED',
    label: t('publichealth:section.calculator.input.employed'),
    iconUrl: {
      active: <UsersIcon size={24} noMargin color="purple-500" />,
      inactive: <UsersIcon size={24} noMargin color="grey-400" />,
    },
  },
  {
    id: 'SELF_EMPLOYED',
    label: t('publichealth:section.calculator.input.selfemployed'),
    iconUrl: {
      active: <UserIcon size={24} noMargin color="purple-500" />,
      inactive: <UserIcon size={24} noMargin color="grey-400" />,
    },
  },
  {
    id: 'STUDENT',
    label: t('publichealth:section.calculator.input.student'),
    iconUrl: {
      active: <BookOpenIcon size={24} noMargin color="purple-500" />,
      inactive: <BookOpenIcon size={24} noMargin color="grey-400" />,
    },
  },
  {
    id: 'OTHER',
    label: t('publichealth:section.calculator.input.otherOccupation'),
    iconUrl: {
      active: <PenToolIcon size={24} noMargin color="purple-500" />,
      inactive: <PenToolIcon size={24} noMargin color="grey-400" />,
    },
  },
];

const getPriceExceptionDescription = (
  t: TFunction
): { [key in QuoteStatus]: string } => ({
  LOW_INCOME: t('publichealth:section.calculator.priceexception.lowincome', {
    income: englishFormattedEuroCurrency(MIN_CAPPED_INCOME, true),
  }),
  OTHER: t('publichealth:section.calculator.priceexception.otherException'),
  SELF_EMPLOYED_MIN_INCOME: t(
    'publichealth:section.calculator.priceexception.selfEmployedMinIncome',
    {
      salary: englishFormattedEuroCurrency(
        MIN_CAPPED_SELF_EMPLOYED_INCOME,
        true
      ),
    }
  ),
  STUDENT_OVER_THIRTY: t(
    'publichealth:section.calculator.priceexception.student'
  ),
});

export const PublicQuoteWidget = ({
  total,
  variables,
  onChange,
}: EmploymentRadioFormProps) => {
  const { employmentStatus, age, annualIncome, haveChildren } = variables;
  const { t } = useTranslation();

  const priceException =
    employmentStatus &&
    age &&
    annualIncome !== undefined &&
    haveChildren !== undefined;

  const priceExceptionStatus =
    priceException &&
    getQuoteStatus({
      employmentStatus,
      monthlyIncome: annualIncome && annualIncome / 12,
      age,
    });

  const displayTotalAndExceptionMessage =
    !priceExceptionStatus ||
    priceExceptionStatus === 'SELF_EMPLOYED_MIN_INCOME';

  return (
    <form className={styles.container}>
      <div className={styles['radio-container']}>
        {employmentData(t).map((element, index) => (
          <div key={element.id}>
            <input
              name="employmentStatus"
              className={`${styles['radio-input']}`}
              type="radio"
              id={element.id}
              value={element.id}
              checked={variables.employmentStatus === element.id}
              onChange={() => onChange('employmentStatus', element.id)}
              required
            />
            <label
              htmlFor={element.id}
              className={classnames(
                'p-label p-label--bordered mr8',
                styles['radio-label'],
                { mt16: index !== 0 }
              )}
            >
              <div className="mr8 d-flex">
                {variables.employmentStatus === element.id
                  ? element.iconUrl.active
                  : element.iconUrl.inactive}
              </div>
              {element.label}
            </label>
          </div>
        ))}
      </div>
      <div className={styles['radio-container']}>
        <CurrencyInput
          className={styles['radio-label']}
          placeholder={t('publichealth:section.calculator.input.income')}
          min={0}
          onChange={(value) => {
            onChange(
              'annualIncome',
              value || value === 0 ? Number(value) : undefined
            );
          }}
          required
        />
        <Input
          className={`mt16 ${styles['radio-label']}`}
          placeholder={t('publichealth:section.calculator.input.age')}
          type="number"
          onChange={(e) => onChange('age', Number(e.target.value))}
          required
        />
        <p className="mt48 p-p tc-grey-600">
          {t('publichealth:section.calculator.input.children.title')}
        </p>
        <div className="d-flex">
          <input
            name="haveChildren"
            className="p-radio"
            type="radio"
            id="yes"
            checked={variables.haveChildren === true}
            onChange={() => onChange('haveChildren', true)}
            required
          />
          <label
            htmlFor="yes"
            className={`p-label p-label--bordered mt8 mr8 ${styles['radio-label']}`}
          >
            {t('publichealth:section.calculator.input.children.yes')}
          </label>
          <input
            name="haveChildren"
            className="p-radio"
            type="radio"
            id="no"
            checked={variables.haveChildren === false}
            onChange={() => onChange('haveChildren', false)}
            required
          />
          <label
            htmlFor="no"
            className={`p-label p-label--bordered mt8 ${styles['radio-label']}`}
          >
            {t('publichealth:section.calculator.input.children.no')}
          </label>
        </div>
      </div>
      <div className={styles.result}>
        {displayTotalAndExceptionMessage && (
          <>
            <h1 className="p-h1 p--serif mt16 tc-primary-500">
              {total
                ? `${t(
                    'publichealth:section.calculator.priceCta.from',
                    'from ~'
                  )} ${englishFormattedEuroCurrency(total, 0)}`
                : '€ —'}
            </h1>
            <p className="p-p tc-grey-500  mb16">
              {t('publichealth:section.calculator.permonth')}
            </p>
          </>
        )}

        {!!priceExceptionStatus && (
          <div className={styles['below-min-container']}>
            <p className="p-p fw-bold">
              {t('publichealth:section.calculator.priceexception.title')}
            </p>
            <p className="p-p mt8">
              {getPriceExceptionDescription(t)[priceExceptionStatus]}
              <a
                className="p-a"
                href={
                  {
                    LOW_INCOME: faq.publicHealthInsuranceContributions,
                    OTHER: faq.publicHealthInsuranceContributions,
                    SELF_EMPLOYED_MIN_INCOME:
                      faq.publicHealthInsuranceContributions,
                    STUDENT_OVER_THIRTY: faq.publicHealthStudentsOver30,
                  }[priceExceptionStatus]
                }
              >
                {t('publichealth:section.calculator.priceexception.link')}
              </a>
            </p>
          </div>
        )}
      </div>
    </form>
  );
};
