import { insurance } from '@getpopsure/private-constants';
import {
  getContributionPrices,
  Provider,
} from '@getpopsure/public-health-insurance-pricing-engine';
import { ContributionPrices } from '@getpopsure/public-health-insurance-pricing-engine/dist/cjs/models';
import { TableProps } from '@popsure/dirty-swan';
import PriceDetails from 'components/PublicTableWithCalculator/priceDetails';
import { useTranslation } from 'next-i18next';
import { getUrlWithTracker } from 'util/getUrlWithTracker';

import { CalculatorVariables } from '../../PublicTableWithCalculator/publicQuoteWidget';

const providerMap: Record<number, Provider> = {
  0: 'BARMER',
  1: 'TK',
  2: 'DAK',
  3: 'AOK',
  4: 'BKK',
};

export const useCellReplacements = ({
  options,
  handleOnCalculateClick,
  providerFilter,
  hasBKKFirmus,
}: {
  options: Partial<CalculatorVariables> | null;
  handleOnCalculateClick?: () => void;
  providerFilter?: Provider | undefined;
  hasBKKFirmus?: boolean;
}): {
  defaultPrice: number | null;
  cellReplacements: TableProps['cellReplacements'];
} => {
  const visibleProviderMap: Provider[] = [
    'BARMER',
    'TK',
    'DAK',
    hasBKKFirmus ? 'BKK' : 'AOK',
  ];
  const { t } = useTranslation();
  const getRawPrice = (providerIndex: number): ContributionPrices | null => {
    const provider: Provider | null = providerMap[providerIndex] || null;
    if (!options) return null;
    const { age, annualIncome, employmentStatus, haveChildren } = options;

    if (
      age &&
      annualIncome !== undefined &&
      employmentStatus &&
      haveChildren !== undefined
    ) {
      return (
        getContributionPrices({
          provider,
          age,
          employmentStatus,
          haveChildren,
          monthlyIncome: annualIncome / 12,
        }) ?? null
      );
    }

    return null;
  };

  const getPrice = (providerIndex: number): number | null => {
    const rawPrice = getRawPrice(providerIndex)?.total;

    return !rawPrice ? null : Math.round(rawPrice);
  };

  const getMininumPrice = (): number | null => {
    if (providerFilter) {
      const providerKey = Object.entries(providerMap).find(
        ([_, value]) => value === providerFilter
      );

      if (!providerKey?.[0]) {
        return null;
      }

      const price = getPrice(Number(providerKey[0]));
      return price !== null ? price : null;
    }

    const prices = Object.entries(providerMap)
      .map(([providerKey, providerName]) => {
        if (!visibleProviderMap.includes(providerName)) {
          return null;
        }

        return getPrice(Number(providerKey));
      })
      .filter((price) => price !== null);

    return prices.length ? Math.min(...(prices as number[])) : null;
  };

  const getHrefWithTracking = (url: string) =>
    typeof window !== 'undefined'
      ? {
          href: getUrlWithTracker(url),
        }
      : {};

  const getPriceCell = (index: number) => {
    const price = getPrice(index);
    const rawPrice = getRawPrice(index);

    if (!price || !rawPrice) {
      return {
        description: (
          <button
            className="ds-interactive-component p-a"
            type="button"
            onClick={handleOnCalculateClick}
          >
            {t('publichealth:section.calculator.priceCta.calculate')}
          </button>
        ),
      };
    }

    return {
      text: `~ €${getPrice(index)}`,
      modalContent: (
        <PriceDetails
          name={providerMap[index] || null}
          contributionPrices={rawPrice}
          pricingInfo={options as CalculatorVariables}
        />
      ),
    };
  };

  const cellReplacements = {
    // English public table
    ctaRow_1_0: getHrefWithTracking(insurance.publicHealth.barmer.signup),
    ctaRow_1_1: getHrefWithTracking(insurance.publicHealth.tk.signup),
    ctaRow_1_2: getHrefWithTracking(insurance.publicHealth.dak.signup),
    ctaRow_1_3: getHrefWithTracking(insurance.publicHealth.aok.signup),
    ctaRow_1_4: getHrefWithTracking(insurance.publicHealth.bkk.signup),
    // German public table
    ctaRow_5_0: getHrefWithTracking(insurance.publicHealth.barmer.signup),
    ctaRow_5_1: getHrefWithTracking(insurance.publicHealth.tk.signup),
    ctaRow_5_2: getHrefWithTracking(insurance.publicHealth.dak.signup),
    ctaRow_5_3: getHrefWithTracking(insurance.publicHealth.aok.signup),
    ctaRow_5_4: getHrefWithTracking(insurance.publicHealth.bkk.signup),
    // English public table
    defaultRow_1_0: getPriceCell(0),
    defaultRow_1_1: getPriceCell(1),
    defaultRow_1_2: getPriceCell(2),
    defaultRow_1_3: getPriceCell(3),
    defaultRow_1_4: getPriceCell(4),
    // German public table
    defaultRow_76_0: getPriceCell(0),
    defaultRow_76_1: getPriceCell(1),
    defaultRow_76_2: getPriceCell(2),
    defaultRow_76_3: getPriceCell(3),
    defaultRow_76_4: getPriceCell(4),
  } as TableProps['cellReplacements'];

  return { cellReplacements, defaultPrice: getMininumPrice() };
};
