import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { SectionHeader } from '../sectionHeader';
import styles from './style.module.scss';

interface Props {
  title?: string;
  children: React.ReactNode;
  additionalInfo?: React.ReactNode;
  sectionId?: string;
  hasBackgroundColor?: boolean;
}

const BASE_CONTAINER_HEIGHT = 80;
const ADDITIONAL_CONTAINER_HEIGHT = 32;

const PriceCalculatorContainer = ({
  title,
  children,
  additionalInfo,
  sectionId = 'calculator',
  hasBackgroundColor,
}: Props) => {
  const [priceCalculatorHeight, setPriceCalculatorHeight] = useState(
    BASE_CONTAINER_HEIGHT
  );

  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.offsetHeight;
      setPriceCalculatorHeight(height);
    }
  }, [elementRef]);

  return (
    <div
      className={classNames({ [styles.container]: hasBackgroundColor })}
      style={{
        backgroundSize: `${
          priceCalculatorHeight + ADDITIONAL_CONTAINER_HEIGHT
        }px`,
      }}
      id={sectionId} // for CTA anchor
    >
      {title && <SectionHeader title={title} titleClass="p-h2" />}
      <div className={styles.background}>
        <div className={`mt24 ${styles['price-calculator']}`} ref={elementRef}>
          {children}
        </div>
      </div>
      {additionalInfo ?? <></>}
    </div>
  );
};

export default PriceCalculatorContainer;
