import { TFunction } from '@getpopsure/i18n-react';
import {
  EmploymentStatus,
  MAX_CAPPED_INCOME,
  Provider,
} from '@getpopsure/public-health-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { ChevronDownIcon } from '@popsure/dirty-swan';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './style.module.scss';

export interface PricingInfo {
  employmentStatus?: EmploymentStatus;
  age?: number;
  annualIncome?: number;
  haveChildren?: boolean;
}

export interface ContributionPricesInfo {
  healthInsurance: { amount: number; percentage?: number };
  additional: { amount: number; percentage?: number };
  longTermCare: { amount: number; percentage?: number };
  total: number;
}

const LONG_TERM_CARE_MIN_AGE = 23;
const RADIUS = 140;
const STROKE = 16;

const renderLongTermContributionDetails = (
  age: number,
  haveChildren: boolean,
  t: TFunction
): React.ReactNode => {
  return (
    <>
      <div className="p-p--small tc-grey-900">
        {t('publichealth:section.calculator.priceDetails.longTermCareDetails')}
      </div>
      {age > LONG_TERM_CARE_MIN_AGE && (
        <div className="p-p--small tc-grey-900 mt8">
          {t('publichealth:section.calculator.priceDetails.longTermCareAge')}
        </div>
      )}
      {haveChildren && age > LONG_TERM_CARE_MIN_AGE && (
        <div className="p-p--small tc-grey-900 mt8">
          {t(
            'publichealth:section.calculator.priceDetails.longTermCareChildren'
          )}
        </div>
      )}
    </>
  );
};

const renderHealthInsuranceContributionDetails = (
  annualIncome: number,
  employmentStatus: EmploymentStatus,
  t: TFunction
): React.ReactNode => {
  if (employmentStatus === 'EMPLOYED') {
    if (annualIncome / 12 < MAX_CAPPED_INCOME) {
      return (
        <div className="p-p--small tc-grey-900">
          {t('publichealth:section.calculator.priceDetails.employed')}
        </div>
      );
    }
    return (
      <>
        <div className="p-p--small tc-grey-900">
          {t(
            'publichealth:section.calculator.priceDetails.employedAboveThreshold1'
          )}
          {englishFormattedEuroCurrency(MAX_CAPPED_INCOME, true)}.
        </div>
        <div className="p-p--small tc-grey-900 mt8">
          {t(
            'publichealth:section.calculator.priceDetails.employedAboveThreshold2'
          )}
        </div>
        <div className="p-p--small tc-grey-900 mt8">
          {t(
            'publichealth:section.calculator.priceDetails.employedAboveThreshold3'
          )}
        </div>
      </>
    );
  }

  if (employmentStatus === 'SELF_EMPLOYED') {
    if (annualIncome / 12 < MAX_CAPPED_INCOME) {
      return (
        <div className="p-p--small tc-grey-900">
          {t('publichealth:section.calculator.priceDetails.selfEmployed')}
        </div>
      );
    }
    return (
      <>
        <div className="p-p--small tc-grey-900">
          {t(
            'publichealth:section.calculator.priceDetails.selfEmployedAboveThreshold1'
          )}
          {englishFormattedEuroCurrency(MAX_CAPPED_INCOME, true)}.
        </div>
        <div className="p-p--small tc-grey-900 mt8">
          {t(
            'publichealth:section.calculator.priceDetails.selfEmployedAboveThreshold2'
          )}
        </div>
        <div className="p-p--small tc-grey-900 mt8">
          {t(
            'publichealth:section.calculator.priceDetails.selfEmployedAboveThreshold3'
          )}
        </div>
      </>
    );
  }

  return <></>;
};

const renderPercentage = (percentage?: number): React.ReactNode =>
  !percentage ? null : `(${percentage}%)`;

export const ContributionPrices = ({
  contributionPrices,
  name,
  pricingInfo,
}: {
  contributionPrices?: ContributionPricesInfo;
  name: Provider;
  pricingInfo?: PricingInfo;
}) => {
  const { t } = useTranslation();
  const [openedContribution, setOpenedContribution] = useState<
    number | undefined
  >(undefined);

  const normalizedRadius = RADIUS - STROKE * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const insuranceContributionPercentage =
    contributionPrices &&
    contributionPrices?.healthInsurance.amount / contributionPrices?.total;
  const additionalContributionPercentage =
    contributionPrices &&
    (contributionPrices?.healthInsurance.amount +
      contributionPrices?.additional.amount) /
      contributionPrices?.total;
  const longTermCareContributionPercentage =
    contributionPrices &&
    (contributionPrices?.healthInsurance.amount +
      contributionPrices?.additional.amount +
      contributionPrices?.longTermCare.amount) /
      contributionPrices?.total;

  const insuranceContributionStrokeDashoffset =
    circumference -
    (insuranceContributionPercentage ?? 0 / 100) * circumference;
  const additionalContributionStrokeDashoffset =
    circumference -
    (additionalContributionPercentage ?? 0 / 100) * circumference;
  const longTermCareContributionStrokeDashoffset =
    circumference -
    (longTermCareContributionPercentage ?? 0 / 100) * circumference;

  return (
    <div className={styles.container}>
      <div className={`wmn4 mt24 ${styles['contribution-prices-container']}`}>
        <div>
          <div className={styles['contribution-prices']}>
            <div
              className={`${styles['color-bar']} ${styles['color-bar--health']}`}
            />
            <button
              className={`w100 ${styles['prices-content-container']}`}
              onClick={() => {
                if (openedContribution === 0) {
                  setOpenedContribution(undefined);
                } else {
                  setOpenedContribution(0);
                }
              }}
              type="button"
            >
              <div className="ml16">
                <div className="p-h4 p--serif">
                  {englishFormattedEuroCurrency(
                    contributionPrices?.healthInsurance.amount ?? 0,
                    2
                  )}
                </div>
                <div className="p-p--small tc-grey-500">
                  {t(
                    'publichealth:section.calculator.priceDetails.healthInsurance'
                  )}
                  {renderPercentage(
                    contributionPrices?.healthInsurance.percentage
                  )}
                </div>
              </div>
              <ChevronDownIcon
                className={classnames(styles['prices-details-icon'], {
                  [styles['prices-details-icon-rotate']]:
                    openedContribution === 0,
                })}
                size={20}
                color="purple-500"
                noMargin
              />
            </button>
          </div>
          <AnimateHeight
            duration={300}
            height={openedContribution === 0 ? 'auto' : 0}
          >
            <div className="d-flex">
              <div
                className={`${styles['description-color-bar']} ${styles['color-bar--health']}`}
              />
              <div className="ml16 p-p--small tc-grey-900 wmx3">
                {contributionPrices &&
                  pricingInfo?.annualIncome &&
                  pricingInfo.employmentStatus &&
                  renderHealthInsuranceContributionDetails(
                    pricingInfo.annualIncome,
                    pricingInfo.employmentStatus,
                    t
                  )}
              </div>
            </div>
          </AnimateHeight>
        </div>
        <div>
          <div className={styles['contribution-prices']}>
            <div
              className={`${styles['color-bar']} ${styles['color-bar--additional']}`}
            />
            <button
              className={`w100 ${styles['prices-content-container']}`}
              onClick={() => {
                if (openedContribution === 1) {
                  setOpenedContribution(undefined);
                } else {
                  setOpenedContribution(1);
                }
              }}
              type="button"
            >
              <div className="ml16">
                <div className="p-h4 p--serif">
                  {englishFormattedEuroCurrency(
                    contributionPrices?.additional.amount ?? 0,
                    2
                  )}
                </div>
                <div className="p-p--small tc-grey-500">
                  {t(
                    'publichealth:section.calculator.priceDetails.additionalTitle'
                  )}
                  {renderPercentage(contributionPrices?.additional.percentage)}
                </div>
              </div>
              <ChevronDownIcon
                className={classnames(styles['prices-details-icon'], {
                  [styles['prices-details-icon-rotate']]:
                    openedContribution === 1,
                })}
                size={20}
                color="purple-500"
                noMargin
              />
            </button>
          </div>
          <AnimateHeight
            duration={300}
            height={openedContribution === 1 ? 'auto' : 0}
          >
            <div className="d-flex">
              <div
                className={`${styles['description-color-bar']} ${styles['color-bar--additional']}`}
              />
              <div className="ml16 wmx3">
                {name === 'AOK' && (
                  <div className="p-p--small tc-grey-900 mt8">
                    {t('publichealth:section.calculator.priceDetails.aok')}
                  </div>
                )}

                {contributionPrices && (
                  <div className="p-p--small tc-grey-900 mt8">
                    {t(
                      'publichealth:section.calculator.priceDetails.additional'
                    )}
                  </div>
                )}
              </div>
            </div>
          </AnimateHeight>
        </div>
        <div>
          <div className={styles['contribution-prices']}>
            <div
              className={`${styles['color-bar']} ${styles['color-bar--long-term']}`}
            />
            <button
              className={`w100 ${styles['prices-content-container']}`}
              onClick={() => {
                if (openedContribution === 2) {
                  setOpenedContribution(undefined);
                } else {
                  setOpenedContribution(2);
                }
              }}
              type="button"
            >
              <div className="ml16">
                <div className="p-h4 p--serif">
                  {englishFormattedEuroCurrency(
                    contributionPrices?.longTermCare.amount ?? 0,
                    2
                  )}
                </div>
                <div className="p-p--small tc-grey-500">
                  {t(
                    'publichealth:section.calculator.priceDetails.longTermCare'
                  )}
                  {renderPercentage(
                    contributionPrices?.longTermCare.percentage
                  )}
                </div>
              </div>
              <ChevronDownIcon
                className={classnames(styles['prices-details-icon'], {
                  [styles['prices-details-icon-rotate']]:
                    openedContribution === 2,
                })}
                size={20}
                color="purple-500"
                noMargin
              />
            </button>
          </div>
          <AnimateHeight
            duration={300}
            height={openedContribution === 2 ? 'auto' : 0}
          >
            <div className="d-flex">
              <div
                className={`${styles['description-color-bar']} ${styles['color-bar--long-term']}`}
              />
              <div className="ml16 p-p--small tc-grey-900 wmx3">
                {contributionPrices &&
                  pricingInfo?.age &&
                  pricingInfo?.employmentStatus &&
                  pricingInfo.haveChildren !== undefined &&
                  renderLongTermContributionDetails(
                    pricingInfo?.age,
                    pricingInfo.haveChildren,
                    t
                  )}
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>
      <div className={`mt24 ${styles['graph-container']}`}>
        <svg height={RADIUS * 2} width={RADIUS * 2}>
          <circle
            className={`${styles.circle} ${styles['inner-circle']}`}
            strokeWidth={STROKE}
            strokeDasharray={`${circumference} ${circumference}`}
            r={normalizedRadius}
            cx={RADIUS}
            cy={RADIUS}
          />
          <circle
            className={`${styles.circle} ${styles['long-term-care-circle']}`}
            strokeWidth={STROKE}
            strokeDasharray={`${circumference} ${circumference}`}
            style={{
              strokeDashoffset: longTermCareContributionStrokeDashoffset ?? 0,
            }}
            r={normalizedRadius}
            cx={RADIUS}
            cy={RADIUS}
          />
          <circle
            className={`${styles.circle} ${styles['additional-circle']}`}
            strokeWidth={STROKE}
            strokeDasharray={`${circumference} ${circumference}`}
            style={{
              strokeDashoffset: additionalContributionStrokeDashoffset ?? 0,
            }}
            r={normalizedRadius}
            cx={RADIUS}
            cy={RADIUS}
          />
          <circle
            className={`${styles.circle} ${styles['health-insurance-circle']}`}
            strokeWidth={STROKE}
            strokeDasharray={`${circumference} ${circumference}`}
            style={{
              strokeDashoffset: insuranceContributionStrokeDashoffset ?? 0,
            }}
            r={normalizedRadius}
            cx={RADIUS}
            cy={RADIUS}
          />
        </svg>
        <div className={`ta-center ${styles['graph-contribution-amount']}`}>
          <div className="p-h4 p--serif">
            ~ {englishFormattedEuroCurrency(contributionPrices?.total ?? 0, 2)}
          </div>
          <div className="p-p">
            {t('publichealth:section.calculator.priceDetails.total')}
          </div>
        </div>
      </div>
    </div>
  );
};
