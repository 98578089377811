import { insurance } from '@getpopsure/private-constants';
import { EmploymentStatus } from '@getpopsure/public-health-insurance-pricing-engine';

const FREELANCER_THRESHOLD_UNDER_51 =
  insurance.privateHealth.freelancerThresholdUnder51;

const FREELANCER_THRESHOLD_51_AND_OVER =
  insurance.privateHealth.freelancerThreshold51OrOlder;

const EMPLOYEE_THRESHOLD = insurance.privateHealth.employeeThreshold;

export const isEligibleForPrivate = ({
  age,
  income,
  employmentStatus,
}: {
  age?: number;
  income?: number;
  employmentStatus?: EmploymentStatus;
}) => {
  if (!(age && income && employmentStatus) || employmentStatus === 'STUDENT') {
    return false;
  }

  let incomeThreshold = EMPLOYEE_THRESHOLD;

  const isFreelancer = ['SELF_EMPLOYED', 'OTHER'].includes(employmentStatus);

  if (isFreelancer) {
    incomeThreshold =
      age > 50
        ? FREELANCER_THRESHOLD_51_AND_OVER
        : FREELANCER_THRESHOLD_UNDER_51;
  }

  return income >= incomeThreshold;
};
